import * as React from 'react';
import { graphql } from 'gatsby';

import Issue from '../components/Issue';

const IndexPage = ({ data }) => {
  const latestIssue = data.allIssue.nodes[0].data;
  return <Issue issue={latestIssue} />;
};

export const query = graphql`
  query {
    allIssue(
      limit: 1
      filter: { data: { draft: { eq: false } } }
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        ...IssueData
      }
    }
  }
`;

export default IndexPage;
